<template>
    <div id="dc-settings">
        <h3>Settings - DirtCheap</h3>

        <!-- Authorize -->
        <!-- <b-button class="dc-button-primary" @click="quickbooksAuthUri">Authorization</b-button> -->
        <!-- <hr /> -->

        <!-- Refresh Token -->
        <!-- <b-button class="dc-button-primary" @click="refreshAccessToken">Refresh Token</b-button> -->
        <hr />

        <!-- Get Customers -->
        <!-- <b-button class="dc-button-primary" @click="getQuickbooksCustomers">Get Customers</b-button> -->
        <hr />

        <!-- Get Customer Types -->
        <!-- <b-button class="dc-button-primary" @click="getQuickbooksCustomerTypes">Get Customer Types</b-button>
        <hr /> -->

        <!-- Get Invoices -->
        <b-button class="dc-button-primary" @click="getQuickbooksInvoices">Get Invoices</b-button>
        <hr />

        <!-- Make Customers In-active -->
        <!-- <b-button class="dc-button-primary" @click="makeCustomersInactive">Customers In-active</b-button> -->

        <div id="dc-settings-footer">
            <span>DirtCheap POS - v {{ version }}</span>
            <span>© Copyright 2020 | Dirt Cheap | All Rights Reserved</span>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import APICalls from "./APICalls";
import { quickbooks_get_auth_uri_endpoint, quickbooks_create_token_endpoint, dirtcheap_quickbooks_refresh_token_endpoint } from "./../../constant/api-endpoints";

export default {
    name: "Settings",
    data() {
        return {
            createQRQuantity: null,
            productsList: [],
            productOptions: [],
            printQrcode: {},
            printQrcodeQuantity: "",
            selectedProduct: null,
            qrcodesProduct: [],
            qrcodesQuantity: [],
            version: "1.0.0",
            allCustomers: [],
            allInvoices: [],
        };
    },
    methods: {
        // Create
        async createQuickbooksAccessToken() {
            let config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
                params: {
                    code: this.$route.query.code,
                },
            };
            console.log(config);
            axios
                .get(quickbooks_create_token_endpoint, config)
                .then((res) => {
                    console.log("Quickbooks Token", res.data);
                })
                .catch((err) => {
                    console.log("Quickbooks Token err", err);
                });
        },
        // // All Customers
        // async getQuickbooksCustomers() {
        //     let range = [1, 1001, 2001, 3001, 4001, 5001, 6001, 7001, 8001, 9001, 10001, 11001, 12001, 13001, 14001, 15001, 16001, 17001, 18001, 19001, 20001];
        //     let status = "all"; // active, inactive, all
        //     for (let index in range) {
        //         try {
        //             let startPosition = range[index];
        //             console.log("Position:", startPosition, status);
        //             let response = await APICalls.getCustomers(startPosition, status);
        //             let customerRes = _.get(response, "QueryResponse.Customer");
        //             if (customerRes) {
        //                 this.allCustomers = this.allCustomers.concat(customerRes);
        //             }
        //         } catch (e) {
        //             console.log(e);
        //         }
        //     }
        //     console.log(JSON.stringify(this.allCustomers, null, 2));
        //     console.log(`Total Customers - ${status}`, this.allCustomers.length);
        // },
        // // Customer Types
        // async getQuickbooksCustomerTypes() {
        //     let response = await APICalls.getCustomerTypes();
        //     let customerTypes = _.get(response, "QueryResponse.CustomerType");
        //     console.log(JSON.stringify(customerTypes, null, 2));
        // },
        // // Invoices
        async getQuickbooksInvoices() {
            let range = [1, 1001, 2001, 3001, 4001, 5001, 6001, 7001, 8001, 9001];
            // let range = [10001, 11001, 12001, 13001, 14001, 15001, 16001, 17001, 18001, 19001];
            // let range = [20001, 21001, 22001, 23001, 24001, 25001, 26001, 27001, 28001, 29001];
            // let range = [30001, 31001, 32001, 33001, 34001, 35001, 36001, 37001, 38001, 39001];
            // let range = [40001, 41001, 42001, 43001, 44001, 45001, 46001, 47001, 48001, 49001];
            // let range = [50001, 51001, 52001, 53001, 54001, 55001, 56001, 57001, 58001, 59001];
            for (let index in range) {
                try {
                    let startPosition = range[index];
                    console.log("Position:", startPosition);
                    let response = await APICalls.getInvoices(startPosition);
                    let invoiceRes = _.get(response, "QueryResponse.Invoice");
                    if (!invoiceRes) console.log(invoiceRes);
                    this.allInvoices = this.allInvoices.concat(invoiceRes);
                } catch (e) {
                    console.log(e);
                }
            }
            console.log(JSON.stringify(this.allInvoices, null, 2));
        },
        // // Make Customers Inactive
        // async makeCustomersInactive() {
        //     let customersId = [];
        //     let successResponse = [];
        //     let errorReponse = [];
        //     for (let index in customersId) {
        //         try {
        //             let id = customersId[index];
        //             console.log("Customer Id:", id);
        //             await APICalls.makeCustomerInactive(id);
        //             // console.log(response.Customer);
        //             console.log(customersId.length, parseInt(index));
        //         } catch (e) {
        //             console.log(e);
        //         }
        //     }
        //     console.log(customersId);
        //     console.log(successResponse);
        //     console.log(errorReponse);
        // },
        // // Refresh
        // async refreshAccessToken() {
        //     axios
        //         .get(dirtcheap_quickbooks_refresh_token_endpoint, {
        //             headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
        //         })
        //         .then((res) => {
        //             this.$toasted.show("Refresh Token Success !", { position: "bottom-center", className: "dc-toast-success", duration: 5000 });
        //             console.log("Refresh Access Token", res.data);
        //         })
        //         .catch((err) => {
        //             console.log("Refresh Access Token", err);
        //         });
        // },
        // // Url
        // async quickbooksAuthUri() {
        //     axios
        //         .get(quickbooks_get_auth_uri_endpoint, {
        //             headers: { Authorization: `Bearer ${localStorage.getItem("api_token")}` },
        //         })
        //         .then((res) => {
        //             console.log("Auth Uri", res.data);
        //             let url = res.data;
        //             window.open(url, "_self");
        //         })
        //         .catch((err) => {
        //             console.log("Auth Uri", err);
        //         });
        // },
    },
    mounted() {
        let code = this.$route.query.code;
        if (code) {
            console.log("Code found - Create Access Token");
            this.createQuickbooksAccessToken();
        } else {
            console.log("Node code found");
        }
    },
};
</script>

<style lang="scss">
#dc-settings {
    .dc-button-primary {
        width: 250px;
        margin-top: 20px;
    }

    table {
        width: 100%;

        th {
            padding: 10px;
            text-align: center;
        }

        td {
            text-align: center;

            img {
                width: 100px;
                height: 100px;
            }
        }
    }

    #dc-settings-footer {
        position: fixed;
        bottom: 0;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        margin-left: -80px;
    }
}
</style>
